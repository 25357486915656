import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import HeadLabel from "../components/head-label"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Hero from "../components/hero"

export default function About() {
  return (
    <Layout>
      <Seo
        title="About Us | 株式会社STARKS | スタークス"
        description="株式会社STARKSの経営理念や代表メッセージを掲載しています。"
        ogpSiteName="About Us | 株式会社STARKSコーポレートサイト"
      />

      <Hero message1={"STARKSについて"} />

      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto mt-10">
          <HeadLabel label={"代表メッセージ"} />

          <div className="px-3 pb-8 typography">
            <div className="my-4">
              私は起業する以前は、美容師として働いていました。先輩方の指導のおかげで、一定の結果は出せるようになりましたが、もともと自分で事業をしたいと考えていたため、一念発起して起業しました。
            </div>
            <div className="my-4">
              弊社のスタッフの中には昔からの知り合いもいますが、出会ったころには仕事に対しての意識が低く、結果も出せなかった彼らが、弊社で働く中でスタッフ同士が切磋琢磨し、仕事への考え方や姿勢が別人のように変わっていく。
            </div>
            <div className="my-4">
              そうして一緒に働く仲間がどんどん成長していく姿を見るということはとても嬉しく感じますし、今後もさらに成長していって欲しいと願っています。
            </div>
            <div className="my-4">
              社員には自由に仕事を楽しんで欲しいです。人生のほとんどを仕事が占めるわけですから、楽しい方が良いに決まっていますよね。それでいて、しっかり稼げる環境を作っていくことが私の役目だと思っています。
            </div>
          </div>

          <HeadLabel label={"会社名の由来"} />
          <div className="px-3 pb-8 typography">
            <div className="text-lg mb-2">MAKE STARS</div>
            <div>
              たくさんのSTARを育てたいという想いから、「MAKE
              STARS＝STARKS」という社名にしました。
            </div>
          </div>

          <HeadLabel label={"経営理念"} />
          <div className="px-3 pb-8 typography">
            社員が成長できる環境を作り、新たなスターを創る
          </div>

          <div className="my-6">
            <div className="flex justify-center">
              <div className="w-1/2 px-2 max-w-[200px]">
                <StaticImage
                  src="../images/ceo.jpeg"
                  alt="CEO"
                  placeholder="blurred"
                  layout="fullWidth"
                  className="rounded-full"
                />
              </div>
              <div className="w-1/2 px-2 self-center text-center">
                <div className="text-sm text-neutral-500">代表取締役社長</div>
                <div className="text-2xl typography">福井 貴大</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-800 px-3 py-6 mt-8 text-white">
        <div className="max-w-4xl mx-auto">
          <HeadLabel label={"会社概要"} color={"before:border-white"} />
          <div className="py-3 typography border-solid border-white border-t-[0.5px] border-b-[0.5px]">
            <div>会社名</div>
            <div className="font-light pt-1">株式会社STARKS</div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>所在地</div>
            <div className="font-light pt-1">
              460-0014 愛知県名古屋市中区富士見町15番39号
            </div>
            <div className="font-semibold">
              <a
                href="https://goo.gl/maps/hGbYubgLV3yxbUiHA"
                target="_blank"
                rel="noreferrer noopener"
              >
                Googleマップ →
              </a>
            </div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>設立</div>
            <div className="font-light pt-1">2019年11月11日</div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>代表者</div>
            <div className="font-light pt-1">代表取締役 福井貴大</div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>事業内容</div>
            <div className="font-light pt-1">
              コンサルティング事業 エステサロン事業 美容商材卸事業
              検査キット販売事業 中古車販売事業
            </div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>取引銀行</div>
            <div className="font-light pt-1">
              愛知銀行 中京銀行 大垣共立銀行 名古屋銀行
            </div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>営業許可</div>
            <div className="font-light pt-1">
              <div>古物商（販売業務・取引業務）</div>
              <div>愛知県公安委員会許可 第541422006200号</div>
            </div>
            <div className="font-light pt-1">
              <div>第一種動物取扱業登録証</div>
              <div>第051381号</div>
            </div>
          </div>

          <div className="py-3 typography border-solid border-gray-100 border-b-[0.5px]">
            <div>沿革</div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2018年1月</div>
              <div className="pt-1">
                美肌専門美容エステサロン「ラボーテ名古屋」開業。
                美容室運営開始。
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2018年11月</div>
              <div className="pt-1">美容物販事業スタート</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2019年11月</div>
              <div className="pt-1">
                美肌専門美容エステサロン「ラボーテ岐阜」開業
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2021年1月</div>
              <div className="pt-1">検査キット販売事業スタート</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2021年4月</div>
              <div className="pt-1">コンサルティング事業スタート</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2022年5月</div>
              <div className="pt-1">中古車販売事業スタート</div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2023年3月</div>
              <div className="pt-1">
                髪質改善サロン「MARQUEE名古屋栄」を開業
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2023年11月</div>
              <div className="pt-1">
                髪質改善サロン「AMNESIA名古屋栄」を開業
              </div>
            </div>
            <div className="my-2 font-light text-sm">
              <div className="text-gray-100 pt-1">2023年11月</div>
              <div className="pt-1">ペットホテル&サロン「MOFUMOFU」を開業</div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </Layout>
  )
}
